/* Webkit-specific scrollbar styles */
.card-select-popup-data-panel::-webkit-scrollbar {
    width: 0 !important; /* 隐藏滚动条宽度 */
    height: 0 !important; /* 隐藏滚动条宽度 */
}

.card-select-popup-data-panel::-webkit-scrollbar-track {
    background: transparent; /* 清除滚动条轨道背景颜色 */
}

.card-select-popup-data-panel::-webkit-scrollbar-thumb {
    background: transparent; /* 清除滚动条拇指部分背景颜色 */
}