.fade-in-section {
    opacity: 0;
    transform: translateY(3vh);
    visibility: hidden;
    transition: opacity 0.65s ease-out, transform 0.65s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: block;
}