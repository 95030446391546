@font-face {
    font-family: 'Gina Demi';
    src: url('Gina-DemiBoldItalic.eot');
    src: local('Gina Demi Bold Italic'), local('Gina-DemiBoldItalic'),
        url('Gina-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-DemiBoldItalic.woff2') format('woff2'),
        url('Gina-DemiBoldItalic.woff') format('woff'),
        url('Gina-DemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-BoldItalic.eot');
    src: local('Gina Bold Italic'), local('Gina-BoldItalic'),
        url('Gina-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-BoldItalic.woff2') format('woff2'),
        url('Gina-BoldItalic.woff') format('woff'),
        url('Gina-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gina Demi';
    src: url('Gina-DemiBold.eot');
    src: local('Gina Demi Bold'), local('Gina-DemiBold'),
        url('Gina-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('Gina-DemiBold.woff2') format('woff2'),
        url('Gina-DemiBold.woff') format('woff'),
        url('Gina-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Heavy.eot');
    src: local('Gina Heavy'), local('Gina-Heavy'),
        url('Gina-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Gina-Heavy.woff2') format('woff2'),
        url('Gina-Heavy.woff') format('woff'),
        url('Gina-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gina Book';
    src: url('Gina-Book.eot');
    src: local('Gina Book'), local('Gina-Book'),
        url('Gina-Book.eot?#iefix') format('embedded-opentype'),
        url('Gina-Book.woff2') format('woff2'),
        url('Gina-Book.woff') format('woff'),
        url('Gina-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Bold.eot');
    src: local('Gina Bold'), local('Gina-Bold'),
        url('Gina-Bold.eot?#iefix') format('embedded-opentype'),
        url('Gina-Bold.woff2') format('woff2'),
        url('Gina-Bold.woff') format('woff'),
        url('Gina-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-RegularItalic.eot');
    src: local('Gina Italic'), local('Gina-RegularItalic'),
        url('Gina-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-RegularItalic.woff2') format('woff2'),
        url('Gina-RegularItalic.woff') format('woff'),
        url('Gina-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-HeavyItalic.eot');
    src: local('Gina Heavy Italic'), local('Gina-HeavyItalic'),
        url('Gina-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-HeavyItalic.woff2') format('woff2'),
        url('Gina-HeavyItalic.woff') format('woff'),
        url('Gina-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-LightItalic.eot');
    src: local('Gina Light Italic'), local('Gina-LightItalic'),
        url('Gina-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-LightItalic.woff2') format('woff2'),
        url('Gina-LightItalic.woff') format('woff'),
        url('Gina-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gina Book';
    src: url('Gina-BookItalic.eot');
    src: local('Gina Book Italic'), local('Gina-BookItalic'),
        url('Gina-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-BookItalic.woff2') format('woff2'),
        url('Gina-BookItalic.woff') format('woff'),
        url('Gina-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Light.eot');
    src: local('Gina Light'), local('Gina-Light'),
        url('Gina-Light.eot?#iefix') format('embedded-opentype'),
        url('Gina-Light.woff2') format('woff2'),
        url('Gina-Light.woff') format('woff'),
        url('Gina-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Medium.eot');
    src: local('Gina Medium'), local('Gina-Medium'),
        url('Gina-Medium.eot?#iefix') format('embedded-opentype'),
        url('Gina-Medium.woff2') format('woff2'),
        url('Gina-Medium.woff') format('woff'),
        url('Gina-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Regular.eot');
    src: local('Gina'), local('Gina-Regular'),
        url('Gina-Regular.eot?#iefix') format('embedded-opentype'),
        url('Gina-Regular.woff2') format('woff2'),
        url('Gina-Regular.woff') format('woff'),
        url('Gina-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-Thin.eot');
    src: local('Gina Thin'), local('Gina-Thin'),
        url('Gina-Thin.eot?#iefix') format('embedded-opentype'),
        url('Gina-Thin.woff2') format('woff2'),
        url('Gina-Thin.woff') format('woff'),
        url('Gina-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-ThinItalic.eot');
    src: local('Gina Thin Italic'), local('Gina-ThinItalic'),
        url('Gina-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-ThinItalic.woff2') format('woff2'),
        url('Gina-ThinItalic.woff') format('woff'),
        url('Gina-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gina';
    src: url('Gina-MediumItalic.eot');
    src: local('Gina Medium Italic'), local('Gina-MediumItalic'),
        url('Gina-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Gina-MediumItalic.woff2') format('woff2'),
        url('Gina-MediumItalic.woff') format('woff'),
        url('Gina-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

