.tc-cv-prompt-top-nav-item:hover {
    cursor: pointer;
}

.tc-cv-prompt-top-nav-item.clicked {
    color: #000000; /* 新的文字颜色 */
    transition: color 0.3s;
}

.tc-cv-prompt-top-nav-item:not(.clicked) {
    color: #ffffff; /* 默认文字颜色 */
}

.tc-cv-prompt-top-nav-block {
    transition: transform 0.3s;
}

.tc-cv-prompt-top-nav-block.left {
    transform: translateX(0);
}

.tc-cv-prompt-top-nav-block.right {
    transform: translateX(82px);
}