.category-button-pc-and-pad:hover {
    cursor: pointer;
}

.category-button-pc-and-pad.clicked {
    /*background: linear-gradient(to bottom, #fafafa, #999999); !* 新的背景颜色 *!*/
    background: linear-gradient(0.21deg, rgba(77, 209, 193, 0.7) 0.18%, rgba(79, 120, 200, 0.7) 25.28%, rgba(143, 81, 184, 0.7) 49.9%, rgba(190, 87, 129, 0.7) 74.03%, rgba(211, 135, 103, 0.7) 96.72%);
    color: #ffffff; /* 新的文字颜色 */
    box-shadow: inset 0px 9.198952674865723px 13.798429489135742px 0px rgba(255, 255, 255, 60%);
    transition: background-color 0.5s, color 0.5s;
    overflow: hidden;
}

.category-button-pc-and-pad:not(.clicked) {
    background: #29292C; /* 默认背景颜色 */
    color: #898B94; /* 默认文字颜色 */
}

.category-button-phone:hover {
    cursor: pointer;
}

.category-button-phone.clicked {
    color: #ffffff; /* 新的文字颜色 */
    transition: background-color 0.5s, color 0.5s;
}

.category-button-phone:not(.clicked) {
    color: #7A7A7A; /* 默认文字颜色 */
}


.category-button-phone-indicator {
    transition: background 0.5s;
}

.category-button-phone-indicator.clicked {
    background: linear-gradient(to right, #4DD1C1 0%, #4F78C8 26%, #8F51B8 51%, #BE5781 76%, #BF7353 100%);
}

.category-button-phone-indicator:not(.clicked) {
    background: transparent; /* 默认背景颜色 */
}