.opacity-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;
    will-change: opacity, visibility;
}

.opacity-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: block;
}